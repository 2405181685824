<template>
      
  <div v-if="authenticated">
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
    >
      <template #toggler>
        <CHeaderNavLink>
          <CButton color="dark" size="lg">
            <CIcon name="cil-user" />            
            <span>{{ user.username }}</span>
          </CButton>          
         
        </CHeaderNavLink>
      </template>
      <CDropdownHeader
        tag="div"
        class="text-center"
        color="light"
      >
        <strong>Settings</strong>
      </CDropdownHeader>
      <CDropdownItem v-on:click="profile()">
        <CIcon name="cil-user"  /> Profile
      </CDropdownItem>
      <CDropdownItem disabled>
        <CIcon name="cil-settings" /> Settings
      </CDropdownItem>
      <CDropdownDivider/>
      
      <CDropdownItem v-on:click="logout()">
        <CIcon name="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdown>
  </div>
  <div v-else>
  <CButton  v-on:click="login()" color="success" class="px-4">Login</CButton>
  </div>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      authenticated: false,
      user: {}
    }
  },

  methods: {
    login() {
      this.$router.push({path: '/login'})
    },
    profile() {
      this.$router.push({path: '/profile'})
    }, 
    logout() {
      // this.$router.push({path: '/logout'})
      this.$cognitoAuth.logout()
      this.$router.go()
    },        
    getUser() {
      this.user = this.$cognitoAuth.getCurrentUser();
    },
    getLoginStatus() {
      this.$cognitoAuth.isAuthenticated((result, success) => {
        
        if (success) {
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
      }); 
    },
  },
  created() {
    this.getLoginStatus()
    if (this.authenticated) { 
      this.getUser() 
    }
  },
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>