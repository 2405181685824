export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/home',
        icon: 'cil-home'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Well-Architected Reports']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Reports',
        to: '/reports',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        to: '/remediation-items',
        name: 'Remediation Items',
        icon: 'cil-sync'
      },    
      {
        _name: 'CSidebarNavItem',
        name: 'Customers',
        to: '/customers',
        icon: 'cil-people'
      },  
      {
        _name: 'CSidebarNavDropdown',
        name: 'Improvements',
        icon: 'cil-list-numbered',
        items: [
          {
            _name: 'CSidebarNavDropdown',
            name: 'AWS',
            icon: 'cil-cloud',
            to: '/improvements/aws'
          }
        ]
      },      
      {
        _name: 'CSidebarNavDropdown',
        name: 'Choices',
        icon: 'cil-list-numbered',
        items: [
          {
            name: 'Well-Architected Framework',
            to: '/choices/wellarchitected',  
          },
          {
            name: 'FTR Lens',
            to: '/choices/foundationaltechni',  
          },
          {
            name: 'Serverless Lens',
            to: '/choices/serverless',  
          },
          {
            name: 'SaaS Lens',
            to: '/choices/softwareasaservice',  
          }                    
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Workloads',
        to: '/workloads',
        icon: 'cil-cog'
      },       
      {
        _name: 'CSidebarNavDropdown',
        name: 'Links',
        icon: 'cil-link',
        items: [
          {
            name: 'AWS WA Framework',
            href: 'https://docs.aws.amazon.com/wellarchitected/latest/framework/welcome.html',
            _class: 'bg-success text-white',
            target: '_blank'            
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'README',
        to: '/readme',
        icon: 'cil-book'
      },                   

    ]
  }
]